import LoginByEmail from "../login-by-email/LoginByEmail";

const Login = ({translations}) => {
    return (
        <section className="row auth-block">
            <div className="container">
                <h2 className="title-block">{translations["authTitle"]}</h2>
                <div className="main">
                    <LoginByEmail translations={translations}/>
                </div>
            </div>
        </section>
    );
};

export default Login;