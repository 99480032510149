import {useEffect, useState} from "react";
import {getAuth} from "firebase/auth";

const Payments = ({translations, setPage}) => {
    const [payments, setPayments] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let email = getAuth().currentUser.email;
            email = process.env.REACT_APP_PAYMENT_MODE === 'test' ? 'test_' + email : email;

            const paymentFromDB = await getPaymentsByEmail(email);
            if (paymentFromDB) {
                setPayments(paymentFromDB.map(p => ({
                    id: p.id,
                    email,
                    createdAt: p.created_at,
                    price: p.price
                })));
            }
        }

        fetchData();
    }, []);

    const getPaymentsByEmail = async email => {
        let response = await fetch(process.env.REACT_APP_SERVER_URL + 'get_payments.php?email='+email);
        return await response.json();
    };

    return (
        <section className="row payments-block">
            <div className="container">
                <h2 className="title-block">{translations["titlePayments"]}</h2>
                <div className="main">
                    <div className="tableItems">
                        <div className="tableHeader">
                            <div className="cell cell-25">{translations.tablePaymentId}</div>
                            <div className="cell cell-25 align-center">{translations.tablePaymentSum}</div>
                            <div className="cell cell-50 align-center">{translations.tablePaymentCreatedAt}</div>
                        </div>
                        <div className="tableContent">
                            {payments.map(payment => (
                                <div className="item" key={payment.id}>
                                    <div className="cell cell-25">
                                        <div className="name">{payment.id}</div>
                                    </div>
                                    <div className="cell cell-25 align-center">
                                        <div className="name black">{payment.price}</div>
                                    </div>
                                    <div className="cell cell-50 align-center">
                                        <div className="name">{payment.createdAt}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="buttons mt20 align-right">
                            <button onClick={() => setPage('index')} type='button' className="btn btn-green">НАЗАД</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Payments;