import {useEffect} from "react";
import {getAuth, isSignInWithEmailLink, signInWithEmailLink, getAdditionalUserInfo } from "firebase/auth";

const SignInWithEmailLink = ({showIframeWithTimer, setAuth}) => {
    useEffect(() => {
        // Confirm the link is a sign-in with email link.
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                // email = window.prompt('Please provide your email for confirmation');
                email = '';
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then(async (result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser

                    try {
                        const {isNewUser} = getAdditionalUserInfo(result);
                        if (isNewUser) {
                            const userId = result.user.uid;
                            const body = {
                                userId,
                                email: result.user.email,
                                emailVerified: result.user.emailVerified,
                            };
                            let response = await fetch(process.env.REACT_APP_SERVER_URL + 'create_user.php', {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify(body)
                            });
                            await response.json();
                        }

                        showIframeWithTimer(true);
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        }
    }, []);

    return <></>;
};

export default SignInWithEmailLink;