import {getAuth, signOut} from "firebase/auth";

const Logout = ({text, link=false, closeMenu, intervalId}) => {
    const logout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        }).finally(() => {
            clearInterval(intervalId);
            closeMenu();
        });

    };

    if (link) {
        return <a href="#" onClick={logout}>{text}</a>
    }

    return (
        <button type='button' className="btn btn-green btn-auth is-desktop" onClick={logout}>{text}</button>
    );
};

export default Logout;