import {useState} from "react";
import {getAuth, sendSignInLinkToEmail } from "firebase/auth";

const LoginByEmail = ({translations}) => {
    const [email, setEmail] = useState();
    const [showAlert, setShowAlert] = useState(false);

    const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: process.env.REACT_APP_URL,
        // This must be true.
        handleCodeInApp: true
    };

    const handleChangeEmail = event => {
        let email = event.target.value;
        email = email.trim();
        if (email) {
            setEmail(event.target.value);
        }
    };
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();

            let email = event.target.value;
            email = email.trim();
            if (email) {
                setEmail(event.target.value);
                signIn();
            }
        }
    };

    const signIn = () => {
        if (email) {
            const auth = getAuth();

            sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                    window.localStorage.setItem('emailForSignIn', email);

                    setShowAlert(true);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ...
                });
        }
    };

    return (
        <>
            {!showAlert && (
                <form>
                    <div className="title">{translations.email}</div>
                    <input type="text" name="email" placeholder={translations.emailPlaceholder} onChange={handleChangeEmail}
                           onKeyDown={handleKeyDown}/>
                    <button type="button" className="btn btn-green wide" onClick={signIn}>{translations.btnLogin}</button>
                    <p className="info-block">{translations.infoLogin}</p>
                </form>
            )}

            {showAlert && (
                <div className="form-auth-success">
                    <p className="send">{translations.alertLogin}</p>
                </div>
            )}
        </>
    );
};

export default LoginByEmail;